import React, { useEffect } from 'react';

import { useLazyQuery } from '../hooks/graph';
const controller = new AbortController();

function MeSettingsReports() {
	const [ adminUserStatsReportRetrieve, { loading: loadingUserReport } ] = useLazyQuery('adminUserStatsReport', {
		fetchPolicy: 'network-only',
		onCompleted: ({ adminUserStatsReport }) => {
			const url = window.URL.createObjectURL(new Blob(adminUserStatsReport));
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', `signalstack-users.csv`); // move to the config?
			document.body.appendChild(link);

			link.click();
			link.parentNode.removeChild(link);
		},
	}, controller);

	const [ adminProviderStatsReportRetrieve, { loading: loadingProviderReport } ] = useLazyQuery('adminProviderStatsReport', {
		fetchPolicy: 'network-only',
		onCompleted: ({ adminProviderStatsReport }) => {
			const url = window.URL.createObjectURL(new Blob(adminProviderStatsReport));
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', `signalstack-brokerages.csv`); // move to the config?
			document.body.appendChild(link);

			link.click();
			link.parentNode.removeChild(link);
		},
	}, controller);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return (
		<div className="mb-4 p-3 bg-light">
			<div>Reports</div>

			<div className="row gx-3 mt-2">
				<div className="col-auto">
					<button type="button" className="btn btn-primary" onClick={() => !loadingUserReport && adminUserStatsReportRetrieve()}>Download user stats</button>
				</div>
				<div className="col">
					<button type="button" className="btn btn-primary" onClick={() => !loadingProviderReport && adminProviderStatsReportRetrieve()}>Download brokerage stats</button>
				</div>
			</div>
		</div>
	);
}

export default MeSettingsReports;
