import React from 'react';

import MeSettingsUser from './me-settings-user';
import MeSettingsUserAccountReward from './me-settings-user-account-reward';
import MeSettingsReferrer from './me-settings-referrer';
import MeSettingsReports from './me-settings-reports';
// reserved / import MeSettingsSubscribers from './me-settings-subscribers';

function MeSettings() {
	return (
		<div id="settings">
			<h3 className="my-3">Admin settings</h3>

			<MeSettingsUser />

			<MeSettingsUserAccountReward />

			<MeSettingsReferrer />

			<MeSettingsReports />

		</div>
	);
}

export default MeSettings;
