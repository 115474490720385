const ENV = {
	'localhost': 'development',
	'127.0.0.1': 'development',
	'beta.signalstack.com': 'staging',
	'app.signalstack.com': 'production',
};

const SERVER = {
	'localhost': 'http://localhost:3000',
	'127.0.0.1': 'http://127.0.0.1:3000',
	'beta.signalstack.com': 'https://base.beta.signalstack.com',
	'app.signalstack.com': 'https://base.app.signalstack.com',
};

export const env = ENV[window.location.hostname] || 'development';
export const base = window.location.protocol + '//' + window.location.host;
export const serverBase = SERVER[window.location.hostname] || 'localhost';
export const isDevelopment = env === 'development';
export const isProduction = env === 'production';

const config = {
	env,
	base,

	isDevelopment,
	isProduction,

	server: {
		base: serverBase,
		baseGraph: serverBase + '/graphql',
		// baseSocket: apiBaseSocket,
	},

	services: {
		stripe: {
			development: {
				publicKey: 'pk_test_51KTtjLKvFpRPi5W8ZydQ96gnwfDMuHSQft1xDIw5CD3MZiEL87zocWxngQ8DG04VY828YMXcXhu6XJR9dvUfzjoM00WskZPgWp',
			},
			staging: {
				publicKey: 'pk_test_51KTtjLKvFpRPi5W8ZydQ96gnwfDMuHSQft1xDIw5CD3MZiEL87zocWxngQ8DG04VY828YMXcXhu6XJR9dvUfzjoM00WskZPgWp',
			},
			production: {
				publicKey: 'pk_live_51KTtjLKvFpRPi5W80JaW7DROgzmV8DqVwiJeHylrhmBFkHh6qQNHxwBkTjakPHeqS73QnRDFnr8ejVgtTq61UyNq00YSpQrzsy',
			},
		}[env],
		mixpanel: {
			development: {
				token: 'a993f8c855eba1693b673e9751f9f224',
			},
			staging: {
				token: 'a993f8c855eba1693b673e9751f9f224',
			},
			production: {
				token: '306fe04aff57264a1ea84f676b40d6a7',
			},
		}[env],
		gtag: {
			id: 'G-PEPVM3MSV6',
		}
	},

	intl: {
		locale: 'en-US',
		formats: {
			datetime: {
				datetime: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					// second: 'numeric',
					hour12: false,
				},
				date: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
				},
				// dateMonth: {
				//   month: 'short', // long
				//   year: '2-digit',
				// },
			},
			number: {
				currency: {
					style: 'currency',
					currency: 'USD',
					currencyDisplay: 'symbol', // 'code',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				percent: {
					style: 'percent',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
			}
		}
	},

	user: {
		firstName: {
			validate: {
				maxLength: 32,
			}
		},
		lastName: {
			validate: {
				maxLength: 32,
			}
		},
	},

	customer: {
		status: {
			values: {
				DRAFT: 1,
			},
			labels: {
				1: 'Draft',
			},
			list: [
				1,
			],
		},
	},

	hook: {
		status: {
			values: {
				ACTIVE: 1,
				INACTIVE: 3,
			},
			labels: {
				1: 'Active',
				3: 'Inactive',
			},
			list: [
				1, 3,
			],
		},
	},

	connector: {
		status: {
			values: {
				ACTIVE: 1,
				INACTIVE: 3,
			},
			labels: {
				// 0: 'Draft',
				1: 'Active',
				3: 'Inactive',
			},
			list: [
				1, 3,
			],
		},

		settings: {
			timeout: 300 * 1000, // millisecs (to wait for oauth completeness)
		},
	},

	account: {
		type: {
			values: {
				// ...
				PASSIVE: 11,
			},
			labels: {
				// ...
				11: 'Passive',
			},
			list: [
				// ...
				11,
			],
		},
	},

	product: {
		type: {
			values: {
				BASE: 1,
				RECURRING: 3,
			},
			labels: {
				1: 'Base',
				3: 'Recurring',
			},
			list: [
				1, 3,
			],
		},
	},

	// experimental
	credits: {
		default: 5, // per month
	},

	// experimental
	order: {
		class: {
			labels: {
				bond: 'Bonds',
				cfd: 'CFDs',
				crypto: 'Crypto',
				forex: 'Forex',
				fund: 'Funds',
				future: 'Futures',
				index: 'Indices',
				option: 'Options',
				stock: 'Stocks',
				warrant: 'Warrants',
				// ...add more
			},
			weights: {
				bond: 33,
				cfd: 13,
				crypto: 12,
				forex: 11,
				fund: 31,
				future: 21,
				index: 32,
				option: 22,
				stock: 1,
				warrant: 23,
				// ...add more
			},
			examples: {
				bond: 'TMUBMUSD10Y', // experimental
				cfd: 'AAPL',
				crypto: 'BTCUSD',
				forex: 'EURUSD',
				fund: 'QQQ', // TQQQ
				future: 'ESU23',
				index: 'EUU', // experimental
				option: 'AAPL_221121C145',
				stock: 'AAPL',
				warrant: 'VCXAW', // experimental
			}
		},
		type: {
			labels: {
				market: 'Market',
				limit: 'Limit',
				stop: 'Stop',
				stop_limit: 'Stop-Limit',
			},
			list: [
				'market', 'limit', 'stop', 'stop_limit',
			],
		},
		action: {
			labels: {
				buy: 'Buy',
				sell: 'Sell',
				buy_to_cover: 'Buy-to-Cover',
				sell_short: 'Sell-Short',
				buy_to_open: 'Buy-to-Open',
				buy_to_close: 'Buy-to-Close',
				sell_to_open: 'Sell-to-Open',
				sell_to_close: 'Sell-to-Close',
				close: 'Close',
				cancel: 'Cancel',
			},
			// reserved
			// list: [
			// 	'',
			// ],
			lists: {
				// expimental / asset or case specific actions
				option: ['buy_to_open', 'buy_to_close', 'sell_to_open', 'sell_to_close'],
			},
		},
		duration: {
			definitions: {
				'atc': 'at the close',
				'ato': 'at the open',
				'aon': 'all or none (aka "fok")',
				'clo': 'at the close (aka "atc", "moc")',
				'cls': 'at the close (aka "atc", "moc")',
				'day': 'current trading day',
				'fak': 'fill and kill',
				'fok': 'fill or kill (aka "aon")',
				'gfa': 'good for auction',
				'gtc': 'good till canceled',
				'gtd': 'good till date',
				'gtt': 'good till time',
				'ioc': 'immediate or cancel',
				'opg': 'at the opening (aka "ato", "moo")',
			},
		},
	},

	// experimental
	provider: {
		scales: {
			bitmex: 0.7,
			coinbase: 0.8,
			etrade: 0.8,
			ibkr: 1.1,
			kraken: 0.7,
			questrade: 1.6,
			robinhood: 1.2,
			saxo: 1.5,
			schwab: 1.8,
			tradier: 1.2,
			tastytrade: 0.9,
			trading212: 0.65,
			tradovate: 1.3,
			webull: 0.6,
			woo: 0.8,
		},
	},

	// experimental
	platform: {
		labels: {
			tradingview: 'TradingView',
			trendspider: 'TrendSpider',
			zapier: 'Zapier',
			// ifttt: 'IFTTT',
			// tc2000: 'TC2000',
		},
		scales: {
			tradingview: 0.8,
			// trendspider: 0.7,
			zapier: 0.7,
		},
		addresses: {
			'52.89.214.238': 'tradingview',
			'34.212.75.30': 'tradingview',
			'54.218.53.128': 'tradingview',
			'52.32.178.7': 'tradingview',
		},
		list: [
			'tradingview', 'trendspider', 'zapier', // 'ifttt', 'tc2000',
		],
	},

	// temporal
	// promotionCode: {
	// 	'2340': {percentOff: 40},
	// }
};

config.tests = {
	// reserved
	// onboardingConnectorCreateWarning: {
	// 	// extra "warning" message with a list of privacy points @Jason
	// 	isActive: true || now > new Date('2023-10-23T00:00:00-08:00'), // MixPanel PST time zone
	// 	isBucket: userId => userId % 2 == 1, // isBucketA
	// },

	// completed, 240125 / Dan: bucketB
	// onboardingWelcome: {
	// 	// popups-based a-la onboarding flow
	// 	isActive: new Date() > new Date('2023-12-08T00:00:00-08:00'), // MixPanel PST time zone
	// 	isBucket: userId => userId % 2 == 1, // isBucketA
	// },
};


export default config;
